<template>
  <esmp-input
    disabled
    :label="label"
    icon="return-left"
  />
</template>

<script>
export default {
  name: 'ContactInfoView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
  },
};
</script>
